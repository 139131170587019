<template>
  <form @submit.prevent="submitCallback">
    <input-group
            ref="purpose"
            :label="$t('transactions.form.purpose')"
            name="purpose"
            v-model="transaction.purpose"
            icon="fa fa-bullseye"
            required
    />

    <input-group
            :label="$t('transactions.form.amount')"
            name="amount"
            type="currency"
            v-model="transaction.amount"
            :min="0"
            icon="fa fa-euro-sign"
            required
    />

    <input-group
            :label="$t('transactions.form.date')"
            name="date"
            type="date"
            v-model="transaction.date"
            icon="fa fa-calendar"
            required
    />

    <select-field
            :label="$t('transactions.form.user')"
            name="users"
            v-model="transaction.user"
            :options="userOptions"
            required
    />

    <select-field
            :label="$t('transactions.form.categories')"
            name="categories"
            v-model="transaction.categories"
            :options="categoryOptions"
            multiple
    />

    <button type="submit" class="button is-primary">{{ $t('common.submit')}}</button>
  </form>
</template>

<script>
import InputGroup from '../basic/InputGroup'
import SelectField from '../basic/SelectField'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'transaction-form',
  components: { SelectField, InputGroup },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    submitCallback: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState({
      users: state => state.users.items,
      categories: state => state.categories.items
    }),
    userOptions () {
      return this.users.map(user => {
        return {
          value: user._id,
          name: user.name
        }
      })
    },
    ...mapGetters([
      'categoryOptions'
    ])
  },
  created () {
    this.$store.dispatch('users/fetch')
    this.$store.dispatch('categories/fetch')
  },
  mounted () {
    this.$nextTick(() => {
      console.log(this.$refs.purpose)
      this.$refs.purpose.focusInput()
    })
  }
}
</script>

<style scoped>

</style>
