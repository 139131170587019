<template>
  <div class="new-transaction">
    <subtitle>{{ $t('transactions.newTitle') }}</subtitle>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>

    <transaction-form
      :transaction="transaction"
      :submit-callback="create"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import TransactionForm from './TransactionForm'
import Subtitle from '../basic/Subtitle'

export default {
  name: 'new-transaction',
  components: {
    Subtitle,
    TransactionForm
  },
  data () {
    return {
      transaction: null,
      error: null
    }
  },
  created () {
    this.$store.dispatch('users/fetch')
    this.$store.dispatch('categories/fetch')
    this.clearTransaction()
  },
  computed: {
    ...mapState({
      users: state => state.users.items,
      user: state => state.user,
      categories: state => state.categories.items,
      accountBook: state => state.accountBooks.current
    }),
    userOptions () {
      return this.users.map(user => {
        return {
          value: user._id,
          name: user.name
        }
      })
    },
    categoryOptions () {
      return this.categories.map(category => {
        return {
          value: category._id,
          name: category.name
        }
      })
    }
  },
  methods: {
    async create () {
      try {
        await this.$store.dispatch('transactions/create', this.transaction)
      } catch (error) {
        this.error = error.response.data.message
      }

      this.$store.commit('showAlertBar', { text: this.$t('transactions.newSuccessMessage'), type: 'success' })

      this.$router.push(`/${this.accountBook.slug}/transactions`)
    },
    clearTransaction () {
      this.transaction = {
        purpose: null,
        amount: null,
        isHousehold: true,
        date: moment().format('YYYY-MM-DD'),
        user: this.user !== null ? this.user._id : null,
        categories: [],
        accountBook: this.accountBook._id
      }
    }
  }
}
</script>

<style scoped>

</style>
